import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CustomTableCell from "./CustomTableCell";
import EuroCupEntry from "../datamodels/EuroCupEntry";

const EuroCupTable = ({ allTriviaData, revealedPlayers, onReveal }) => {
  const [tableHeaders, setTableHeaders] = useState([]);

  useEffect(() => {
    if (allTriviaData.length > 0) {
      const firstEntry = allTriviaData[0];
      setTableHeaders(firstEntry.getNonEmptyHeaders());
    } else {
      console.log("Could not get any trivia data.");
    }
  }, [allTriviaData]);

  // Function to calculate if the cell should be revealed
  const shouldReveal = (currentRow) => {
    return revealedPlayers.some(
      (revealedPlayer) => revealedPlayer.player.playerId === currentRow.playerId
    );
  };

  return (
    allTriviaData.length > 0 && (
      <Box sx={{ padding: 2 }}>
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#1c1c1c", marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <TableCell key={index} style={{ textAlign: "center" }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* All trivia rows */}
              {allTriviaData.slice(0, 10).map((entry, index) => {
                return (
                  <TableRow key={"answerrow_" + index}>
                    {tableHeaders.map((tableColumn) => {
                      const propertyName =
                        EuroCupEntry.columnNameToAttributeKey[tableColumn];
                      const isInitiallyRevealed =
                        (tableColumn !== "Age" &&
                          tableColumn !== "Nationality" &&
                          tableColumn !== "Position" &&
                          tableColumn !== "Player Name") ||
                        shouldReveal(entry);

                      return (
                        <CustomTableCell
                          key={"answercol_" + propertyName}
                          header={propertyName}
                          playerData={entry}
                          guessCount={0} // Always using 0 guess count since it is not used
                          onReveal={onReveal}
                          isInitiallyRevealed={isInitiallyRevealed}
                          showRevealButton={tableColumn !== "Player Name"}
                        />
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  );
};

export default EuroCupTable;
