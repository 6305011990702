import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { addDays, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const CountdownTimer = ({ isFloating = false }) => {
  const [timeLeft, setTimeLeft] = useState("");

  const floatStyle = {
    position: "absolute",
    left: "0%",
    top: "50%",
    transform: "translateY(-50%)",
    width: "100px",
    fontFamily: "Arial",
    whiteSpace: "pre-line",
  };
  const floatText = "Next daily game in\n" + timeLeft;

  const inlineStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial",
    fontWeight: "bold",
  };
  const inlineText = "Next daily game in " + timeLeft;

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().toISOString();
      const timeZone = "America/Los_Angeles";
      const zonedTime = utcToZonedTime(now, timeZone);
      const tomorrow = startOfDay(addDays(zonedTime, 1));

      const diff = tomorrow - zonedTime;

      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor((diff / (1000 * 60)) % 60)
        .toString()
        .padStart(2, "0");
      const seconds = Math.floor((diff / 1000) % 60)
        .toString()
        .padStart(2, "0");

      setTimeLeft(`${hours}:${minutes}:${seconds}`);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Choose the style and text based on the isFloating prop
  const style = isFloating ? floatStyle : inlineStyle;
  const text = isFloating ? floatText : inlineText;

  return (
    <Typography variant="body1" sx={style}>
      {text}
    </Typography>
  );
};

export default CountdownTimer;
