class TransferPlayer {
  constructor(
    name,
    playerUrl,
    age,
    nationality,
    flagUrl,
    position,
    positionShort,
    marketValue,
    numericMarketValue,
    fee,
    numericFee,
    transferUrl,
    transferDate,
    fromClub,
    fromClubUrl,
    toClub,
    toClubUrl,
    publishDate = "N/A"
  ) {
    this.name = name || "N/A";
    this.playerUrl = playerUrl || "N/A";
    if (playerUrl) {
      const playerIds = playerUrl.split("https://")[1].split("/");
      this.playerId = playerIds[1] + "_" + playerIds[4].trim();
    } else {
      this.playerId = "N/A";
    }
    this.age = age || "N/A";
    this.nationality = nationality || "N/A";
    this.flagUrl = flagUrl || "N/A";
    this.position = position || "N/A";
    this.positionShort = positionShort || "N/A";
    this.marketValue = marketValue || "N/A";
    this.numericMarketValue = isNaN(parseInt(numericMarketValue, 10))
      ? 0
      : parseInt(numericMarketValue, 10) || 0;
    this.fee = fee || "N/A";
    this.numericFee = isNaN(parseInt(numericFee, 10))
      ? 0
      : parseInt(numericFee, 10) || 0;
    this.transferUrl = transferUrl || "N/A";
    this.transferDate = transferDate || "N/A";
    this.fromClub = fromClub || "N/A";
    this.fromClubUrl = fromClubUrl || "N/A";
    this.toClub = toClub || "N/A";
    this.toClubUrl = toClubUrl || "N/A";
  }

  static columnToPropertyMap = {
    "Player Name": "name",
    "Player URL": "playerUrl",
    Age: "age",
    Nationality: "nationality",
    "Flag URL": "flagUrl",
    Position: "position",
    "Position Short": "positionShort",
    Marketvalues: "marketValue",
    "Numeric Marketvalues": "numericMarketValue",
    Fee: "fee",
    "Numeric Fee": "numericFee",
    "Transfer URL": "transferUrl",
    "Transfer Date": "transferDate",
    "From Club": "fromClub",
    "From Club URL": "fromClubUrl",
    "To Club": "toClub",
    "To Club URL": "toClubUrl",
  };

  static createFromObject(playerObject) {
    return new TransferPlayer(
      playerObject["Player Name"],
      playerObject["Player URL"],
      playerObject["Age"],
      playerObject["Nationality"],
      playerObject["Flag URL"],
      playerObject["Position"],
      playerObject["Position Short"],
      playerObject["Marketvalues"],
      playerObject["Numeric Marketvalues"],
      playerObject["Fee"],
      playerObject["Numeric Fee"],
      playerObject["Transfer URL"],
      playerObject["Transfer Date"],
      playerObject["From Club"],
      playerObject["From Club URL"],
      playerObject["To Club"],
      playerObject["To Club URL"]
    );
  }

  // Example of a method
  displayInfo() {
    return `${this.name}, ${this.age}, ${this.nationality}, plays as ${this.position}`;
  }
}

export default TransferPlayer;
