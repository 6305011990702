import React from "react";
import {
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CustomTableCell from "./CustomTableCell";
import TransferPlayer from "../datamodels/TransferPlayer";

const TRANSFER_HEADERS_IN_TABLE = [
  "Player Name",
  "Nationality",
  "Age",
  "Position",
  "Marketvalues",
  "Fee",
  "Transfer Date",
  "From Club",
  "To Club",
];

const TRANSFER_HEADERS_DISPLAY = [
  "Player Name",
  "Nationality",
  "Age (at the time of transfer)",
  "Position",
  "Marketvalue (at the time of transfer)",
  "Transfer Fee",
  "Transfer Date",
  "From Club",
  "To Club",
];

function isWithinSixMonths(providedDate, answerDate) {
  // Convert provided and answer dates to Date objects if they are not already
  const provided = new Date(providedDate);
  const answer = new Date(answerDate);

  // Calculate the date 6 months before and after the answer date
  const sixMonthsBefore = new Date(answer);
  sixMonthsBefore.setMonth(answer.getMonth() - 6);

  const sixMonthsAfter = new Date(answer);
  sixMonthsAfter.setMonth(answer.getMonth() + 6);

  // Check if the provided date falls within the range
  return provided >= sixMonthsBefore && provided <= sixMonthsAfter;
}

function isWithinValueRange(providedValue, answerValue, range = 5e6) {
  return Math.abs(providedValue - answerValue) <= range;
}

function calculateBackgroundColor(
  todayPlayerData,
  guessedPlayerData,
  propertyName
) {
  let guessedValue, expectedValue;
  // console.log(propertyName);

  switch (propertyName) {
    case "age":
      // For 'age', directly use propertyName as it's the same
      guessedValue = guessedPlayerData[propertyName];
      expectedValue = todayPlayerData[propertyName];
      break;
    case "fee":
      // Use specific properties if they differ from the propertyName
      guessedValue = guessedPlayerData["numericFee"];
      expectedValue = todayPlayerData["numericFee"];
      break;
    case "marketValue":
      guessedValue = guessedPlayerData["numericMarketValue"];
      expectedValue = todayPlayerData["numericMarketValue"];
      break;
    case "transferDate":
      const guessedDate = new Date(guessedPlayerData[propertyName]);
      const expectedDate = new Date(todayPlayerData[propertyName]);
      if (isNaN(guessedDate.getTime()) || isNaN(expectedDate.getTime())) {
        console.error("One or both of the date strings are invalid.");
        return "#FBF6EE"; // Default or error color
      }
      if (isWithinSixMonths(guessedDate, expectedDate)) {
        return "#65B741";
      } else if (guessedDate < expectedDate) {
        return "#C1F2B0";
      } else if (guessedDate > expectedDate) {
        return "#FFB534";
      }
      return "FBF6EE";
    default:
      // Handle non-numeric, non-date properties directly
      return todayPlayerData[propertyName] === guessedPlayerData[propertyName]
        ? "#65B741"
        : "#FBF6EE";
  }

  // Determine color based on numeric comparison, for 'age', 'fee', and 'marketValue'
  if (guessedValue !== undefined && expectedValue !== undefined) {
    if (
      (propertyName === "fee" || propertyName === "marketValue") &&
      isWithinValueRange(guessedValue, expectedValue)
    )
      return "#65B741";
    // Ensure values are defined
    return guessedValue < expectedValue
      ? "#C1F2B0"
      : guessedValue > expectedValue
      ? "#FFB534"
      : "#65B741";
  } else {
    console.error(
      "One or both values are undefined for propertyName:",
      propertyName
    );
    return "#FBF6EE"; // Return default color if there's an error
  }
}

const PlayerTransferTable = ({
  todayPlayerData,
  guessCount,
  guessedPlayersData,
  handleReveal,
  MAX_GUESS_COUNT,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  // Function to calculate if the cell should be initially revealed
  const shouldReveal = (propertyName) => {
    return guessedPlayersData.some(
      (guessedPlayer) =>
        todayPlayerData[propertyName] === guessedPlayer.player[propertyName] ||
        (propertyName === "transferDate" &&
          isWithinSixMonths(
            guessedPlayer.player[propertyName],
            todayPlayerData[propertyName]
          )) ||
        (propertyName === "fee" &&
          isWithinValueRange(
            guessedPlayer.player["numericFee"],
            todayPlayerData["numericFee"]
          )) ||
          (propertyName === "marketValue" &&
          isWithinValueRange(
            guessedPlayer.player["numericMarketValue"],
            todayPlayerData["numericMarketValue"]
          ))
    );
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: matches ? "none" : "100%" }}>
      <Table>
        <TableHead>
          <TableRow>
            {TRANSFER_HEADERS_DISPLAY.map((header, index) => (
              <TableCell key={index} style={{ textAlign: "center" }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Player's answer row */}
          <TableRow key="answerrow">
            {TRANSFER_HEADERS_IN_TABLE.map((header, index) => {
              const propertyName = TransferPlayer.columnToPropertyMap[header];
              const isInitiallyRevealed =
                guessCount >= MAX_GUESS_COUNT || shouldReveal(propertyName);

              return (
                <CustomTableCell
                  key={"answercol_" + index}
                  header={propertyName}
                  playerData={todayPlayerData}
                  guessCount={guessCount}
                  onReveal={() => handleReveal(propertyName)}
                  isInitiallyRevealed={isInitiallyRevealed}
                  showRevealButton={header !== "Player Name"}
                />
              );
            })}
          </TableRow>
          {/* Wrong guesses rows */}
          {guessedPlayersData.length > 0 && (
            <TableRow>
              <TableCell
                colSpan={TRANSFER_HEADERS_DISPLAY.length}
                style={{ textAlign: "center" }}>
                Previous guesses
              </TableCell>
            </TableRow>
          )}
          {guessedPlayersData.map((guessedPlayer, guessedPlayerIndex) => (
            <TableRow key={"guessrow_" + guessedPlayerIndex}>
              {TRANSFER_HEADERS_IN_TABLE.map((header, headerIndex) => {
                const propertyName = TransferPlayer.columnToPropertyMap[header];
                const backgroundColor = calculateBackgroundColor(
                  todayPlayerData,
                  guessedPlayer.player,
                  propertyName
                );
                return (
                  <CustomTableCell
                    key={"guesscol_" + headerIndex}
                    header={propertyName}
                    playerData={guessedPlayer.player}
                    guessCount={guessCount}
                    onReveal={() => handleReveal(propertyName)}
                    isInitiallyRevealed={true} // Always reveal guessed player data
                    revealColor={backgroundColor}
                  />
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlayerTransferTable;
