import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Tab,
  Tabs,
  Modal,
  styled,
} from "@mui/material";
import Marquee from "react-fast-marquee";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Icon for "How to play"
import LocalCafeIcon from "@mui/icons-material/LocalCafe"; // Icon for "Buy me a coffee"
import { pages } from "../App.js";

const LogoImage = styled("img")({
  // Adjust the styling as necessary
  maxWidth: "200px", // Example height, adjust as needed
  marginRight: "16px",
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600, // Adjust width as necessary
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TotDHelpParagraphs = [
  "Welcome to \"Transfer of the Day\" trivia, where football transfers aren't just news--they're puzzles waiting to be solved!",
  "Think you know your transfers? Type away in the search box using the hints given, and see if you can uncover the mystery player lighting up the transfer headlines.",
  "Here's a tip: Clue colors are your best friends! Green lights up for a perfect match, orange hints you're aiming too high or too recent, and light green suggests you're looking too low or too far back in the dates.",
  "We're talking big moves here--significant fees, free agents with hefty market values, you name it. If they've made waves in the transfer market, they could be in this game. All players are either transferred from or to a club in the 5 major European football leagues.",
  "You've got 7 shots to nail the Transfer of the Day. Stuck? Use a guess to reveal a clue, but spend wisely—bragging rights are on the line for those who guess with the fewest tries!",
  "A new Transfer is picked every day and the games are reset at midnight! Please come back tomorrow for a new round of challenge.",
];

const EuroCupHelpParagraphs = [
  "Welcome to the Euro Cup Trivia Game! Test your football knowledge by guessing the top 10 players in a given category. Each correct guess earns you 200 points, while each wrong guess costs you 20 points.",
  "Stuck? Use 50 points to reveal a critical hint about the player. This can help guide you to the right answer.",
  "Simply type your guesses into the search box using the provided hints. Correct guesses boost your score, while wrong ones deduct points.",
  "Players in this game are key figures from Euro Cup history. They’ve made significant contributions, from scoring goals to making crucial saves.",
  "A new set of players and categories are featured daily. Come back each day for a fresh challenge!"
];

// TabPanel component to show the content of each tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Function to generate tab panel properties
function tabStyleProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      // border: "1px solid black",
      margin: "0 5px",
    },
  };
}

export const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openTutorialModal, setOpen] = useState(false); // State to manage modal visibility
  const [tabIndex, setTabIndex] = useState(0);
  const [openKofiModal, setOpenKofi] = useState(false); // State to manage modal visibility

  // Function to handle opening the modal
  const handleOpenTutorial = () => setOpen(true);
  const handleOpenKofi = () => setOpenKofi(true);
  // Function to handle closing the modal
  const handleCloseTutorial = () => setOpen(false);
  const handleCloseKofi = () => setOpenKofi(false);

  const pageKeys = Object.keys(pages);
  const [tabValue, setValue] = useState(0);

  useEffect(() => {
    const currentPage = location.pathname.substring(1); // remove leading '/'
    const pageIndex = pageKeys.findIndex(
      (key) =>
        key.replaceAll("_", "").toLowerCase() === currentPage.toLowerCase()
    );
    if (pageIndex !== -1) {
      setValue(pageIndex);
    }
  }, [location.pathname, pageKeys]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/${pageKeys[newValue].replaceAll("_", "")}`);
    window.location.reload();
  };

  const handleHelpTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "black",
      }}>
      <Toolbar>
        {/* Replace MenuIcon with your logo */}
        <Link to="/">
          <LogoImage src="/futdle-logo-color.png" alt="Futdle Logo" />
        </Link>

        {/* Rest of the items are pushed to the right */}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            textColor="inherit"
            TabIndicatorProps={{ style: { backgroundColor: "white" } }}>
            {pageKeys.map((key, index) => (
              <Tab
                key={key}
                label={pages[key]}
                sx={{
                  color: "white",
                  border: "1px solid white",
                  margin: "0 5px",
                }}
              />
            ))}
          </Tabs>
        </Box>

        {/* IconButton with How to play icon */}
        <IconButton
          aria-label="How to play"
          size="large"
          color="primary"
          onClick={handleOpenTutorial}>
          <HelpOutlineIcon />
        </IconButton>
        {/* Define the Modal component */}
        <Modal
          open={openTutorialModal}
          onClose={handleCloseTutorial}
          aria-labelledby="how-to-play-title"
          aria-describedby="how-to-play-description">
          <Box sx={modalStyle}>
            <Tabs
              value={tabIndex}
              onChange={handleHelpTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="How to play"
              centered>
              <Tab label="Transfer of the Day" {...tabStyleProps(0)} />
              <Tab label="Euro Cup Trivia" {...tabStyleProps(1)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <Typography
                variant="h4"
                component="h2"
                align="center"
                gutterBottom>
                How to play<br></br>"Transfer of the Day"
              </Typography>
              {TotDHelpParagraphs.map((text, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "start", mt: 2 }}>
                  <StyledParagraph key={index} text={text} />
                </Box>
              ))}
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Typography
                variant="h4"
                component="h2"
                align="center"
                gutterBottom>
                How to play<br></br>"Euro Cup Trivia"
              </Typography>
              {EuroCupHelpParagraphs.map((text, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "start", mt: 2 }}>
                  <StyledParagraph key={index} text={text} />
                </Box>
              ))}
            </TabPanel>
          </Box>
        </Modal>

        {/* IconButton with Buy me a coffee icon */}
        <IconButton
          aria-label="Buy me a coffee"
          size="large"
          color="primary"
          onClick={handleOpenKofi}>
          <LocalCafeIcon />
        </IconButton>
        {/* Define the Modal component for Ko-fi from https://ko-fi.com/Manage/donation-widget-setup */}
        <Modal
          open={openKofiModal}
          onClose={handleCloseKofi}
          aria-labelledby="how-to-play-title"
          aria-describedby="how-to-play-description">
          <Box sx={modalStyle}>
            <iframe
              id="kofiframe"
              src="https://ko-fi.com/futdleio/?hidefeed=true&widget=true&embed=true&preview=true"
              style={{
                border: "none",
                width: "100%",
                padding: "4px",
                background: "#f9f9f9",
              }}
              height="680"
              title="futdleio"></iframe>
          </Box>
        </Modal>
      </Toolbar>
    </AppBar>
  );
};

const StyledParagraph = ({ text }) => {
  // Regular expression to match the delimiters: ?, ,, ., :, or --
  const regex = /(.*?)(\?|,|\.|:|!|--)(.*)/;
  const match = text.match(regex);

  // Include the delimiter with the first part and trim any whitespace
  const firstPart = `${match[1]}${match[2]}`;
  const restPart = match[3];
  return (
    <Typography variant="body1" fontFamily="sans-serif" gutterBottom>
      • <strong>{firstPart}</strong>
      {restPart}
    </Typography>
  );
};

export const AnnouncementBar = () => {
  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          width: "100%", // Ensure the bar spans the entire width
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "2rem", // Adjust the height as needed
          backgroundColor: "secondary.dark", // Or any color from your theme
        }}>
        {/* Use the MarqueeText styled component */}
        <Marquee speed={100} direction="right" gradient={true}>
          <Typography
            sx={{
              padding: 1,
              textAlign: "center",
              fontStyle: "italic",
              fontSize: "16px",
              backgroundColor: "secondary.dark",
            }}>
            New game added for Euro Cup!
          </Typography>
        </Marquee>
      </Box>
    </>
  );
};
