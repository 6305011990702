import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import ReactConfetti from "react-confetti";

const shakeAnimation = keyframes`
    0% { transform: translateX(0px); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0px); }
`;

const MultipleAnswersGuessBar = ({ guessPts, guessState, handleSubmit }) => {
  // Determine message based on the state
  const getMessage = () => {
    if (guessState === "NoPlayerSelected") {
      return "Please submit a guess ☝️";
    } else if (guessState === "CorrectGuess") {
      return `Congratulations! You've guessed correctly with ${guessPts} points.`;
    } else if (guessState === "PlayerRevealed") {
      return "Correct player guess! + 200 pts";
    } else if (guessState === "WrongGuess") {
      return "Wrong guess (-20 points)! Please try again :(";
    } else if (guessState === "PlayerAlreadyGuessed") {
      return "Player already guessed";
    }
    return "Please submit a guess ☝️";
  };

  return (
    <Box paddingY="12px">
      <Button
        variant="contained"
        disabled={guessState === "CorrectGuess"}
        onClick={handleSubmit}
        sx={{ margin: "10px" }}>
        Submit Guess
      </Button>
      {guessState === "CorrectGuess" && (
        <ReactConfetti numberOfPieces={1000} recycle={false} />
      )}
      <Typography
        sx={{
          fontSize: "20px",
          color:
            guessState === "CorrectGuess"
              ? "green"
              : guessState.startsWith("Wrong") ||
                guessState === "PlayerAlreadyGuessed"
              ? "red"
              : "inherit",
          ...((guessState === "WrongGuess" ||
            guessState === "PlayerAlreadyGuessed") && {
            animation: `${shakeAnimation} 0.5s ease-in-out`,
          }),
        }}>
        {getMessage()}
      </Typography>
      <Typography variant="h6" sx={{ textAlign: "right", fontWeight: "bold" }}>
        Current Points: {guessPts}
      </Typography>
    </Box>
  );
};

export default MultipleAnswersGuessBar;
