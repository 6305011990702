import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fbf6ee", // Primary color extracted from the logo
      dark: "darkgray", // Optionally if you want a darker shade
    },
    secondary: {
      main: "#65b741", // Secondary color extracted from the logo
      dark: "#dede50", // Optionally if you want a darker shade
    },
    // Add more colors as needed
  },
  typography: {
    fontFamily: "MontserratAlternates, Arial, sans-serif",
    // Define other typography styles here
  },
  components: {
    // You can define component-specific styles here, for example:
    MuiTable: {
      styleOverrides: {
        root: {
          width: "100%",
          borderCollapse: "collapse",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "1px solid #ddd",
          padding: "10px",
          minWidth: "100px",
        },
        head: {
          backgroundColor: "#4caf50",
          color: "white",
          fontSize: "16px",
          fontWeight: "bold"
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "primary.dark",
        },
      },
    },
    // Define other component overrides here
  },
  // You can add other global styles and overrides here
});

export default theme;
