class EuroCupEntry {
  constructor(
    name,
    playerUrl,
    age,
    playerImageUrl,
    nationality,
    flagUrl,
    position,
    positionShort,
    marketValue,
    numericMarketValue,
    appearances,
    assists,
    penaltyKicks,
    minutesPlayed,
    minutesPerGoal,
    goalsPerMatch,
    goals,
    club,
    clubUrl,
    caps,
    matches,
    cleanSheets,
    goalsConceded,
    minuteOfPlay,
    minutesPerGoalAgainst,
    percentage,
    substitutionsOn,
    substitutionsOff,
    pts,
    manager,
    date,
    team1,
    team1FlagUrl,
    team2,
    team2FlagUrl,
    matchResult,
    ageAtThatTime
  ) {
    this.name = name || "N/A";
    this.playerUrl = playerUrl || "N/A";
    if (playerUrl) {
      const playerIds = playerUrl.split("https://")[1].split("/");
      this.playerId = playerIds[1] + "_" + playerIds[4].trim();
    } else {
      this.playerId = "N/A";
    }
    this.age = age || "N/A";
    this.playerImageUrl = playerImageUrl || "N/A";
    this.nationality = nationality || "N/A";
    this.flagUrl = flagUrl || "N/A";
    this.position = position || "N/A";
    this.positionShort = positionShort || "N/A";
    this.marketValue = marketValue || "N/A";
    this.numericMarketValue = isNaN(parseInt(numericMarketValue, 10))
      ? 0
      : parseInt(numericMarketValue, 10) || 0;
    this.appearances = appearances || "N/A";
    this.assists = assists || "N/A";
    this.penaltyKicks = penaltyKicks || "N/A";
    this.minutesPlayed = minutesPlayed || "N/A";
    this.minutesPerGoal = minutesPerGoal || "N/A";
    this.goalsPerMatch = goalsPerMatch || "N/A";
    this.goals = goals || "N/A";
    this.club = club || "N/A";
    this.clubUrl = clubUrl || "N/A";
    this.caps = caps || "N/A";
    this.matches = matches || "N/A";
    this.cleanSheets = cleanSheets || "N/A";
    this.goalsConceded = goalsConceded || "N/A";
    this.minuteOfPlay = minuteOfPlay || "N/A";
    this.minutesPerGoalAgainst = minutesPerGoalAgainst || "N/A";
    this.percentage = percentage || "N/A";
    this.substitutionsOn = substitutionsOn || "N/A";
    this.substitutionsOff = substitutionsOff || "N/A";
    this.pts = pts || "N/A";
    this.manager = manager || "N/A";
    this.date = date || "N/A";
    this.team1 = team1 || "N/A";
    this.team1FlagUrl = team1FlagUrl || "N/A";
    this.team2 = team2 || "N/A";
    this.team2FlagUrl = team2FlagUrl || "N/A";
    this.matchResult = matchResult || "N/A";
    this.ageAtThatTime = ageAtThatTime || "N/A";
  }

  static createFromObject(playerObject) {
    return new EuroCupEntry(
      playerObject["Player Name"],
      playerObject["Player URL"],
      playerObject["Age"],
      playerObject["Player Image URL"],
      playerObject["Nationality"],
      playerObject["Flag URL"],
      playerObject["Position"],
      playerObject["Position Short"],
      playerObject["Market Value"],
      playerObject["Numeric Market Value"],
      playerObject["Appearances"],
      playerObject["Assists"],
      playerObject["Penalty Kicks"],
      playerObject["Minutes Played"],
      playerObject["Minutes Per Goal"],
      playerObject["Goals Per Match"],
      playerObject["Goals"],
      playerObject["Club"],
      playerObject["Club URL"],
      playerObject["Caps"],
      playerObject["Matches"],
      playerObject["Clean Sheets"],
      playerObject["Goals Conceded"],
      playerObject["Minute of Play"],
      playerObject["Minutes Per Goal Against"],
      playerObject["Percentage"],
      playerObject["Substitutions On"],
      playerObject["Substitutions Off"],
      playerObject["Pts"],
      playerObject["Manager"],
      playerObject["Date"],
      playerObject["Team 1"],
      playerObject["Team 1 Flag URL"],
      playerObject["Team 2"],
      playerObject["Team 2 Flag URL"],
      playerObject["Match Result"],
      playerObject["Age at that time"]
    );
  }

  static attributeKeyToColumnName = {
    name: "Player Name",
    age: "Age",
    nationality: "Nationality",
    position: "Position",
    positionShort: "Position Short",
    marketValue: "Market Value",
    numericMarketValue: "Numeric Market Value",
    appearances: "Appearances",
    assists: "Assists",
    penaltyKicks: "Penalty Kicks",
    minutesPlayed: "Minutes Played",
    minutesPerGoal: "Minutes Per Goal",
    goalsPerMatch: "Goals Per Match",
    goals: "Goals",
    club: "Club",
    caps: "National Team Apperances",
    matches: "Matches",
    cleanSheets: "Clean Sheets",
    goalsConceded: "Goals Conceded",
    minuteOfPlay: "Minute Of Play",
    minutesPerGoalAgainst: "Minutes Per Goal Against",
    percentage: "Percentage of Clean Sheets",
    substitutionsOn: "Substitutions On",
    substitutionsOff: "Substitutions Off",
    pts: "Golden Boot Pts",
    manager: "Manager",
    date: "Match Date",
    team1: "Team 1",
    team2: "Team 2",    
    matchResult: "Match Result",
    ageAtThatTime: "Age At That Time",
  };

  static columnNameToAttributeKey = Object.fromEntries(
    Object.entries(this.attributeKeyToColumnName).map(([key, value]) => [value, key])
  );

  // Example of a method
  displayInfo() {
    return `${this.name}, ${this.age}, ${this.nationality}, plays as ${this.position}`;
  }

  getNonEmptyHeaders() {
    if (this !== null && this !== undefined) {
      return Object.keys(this)
        .filter((key) => this[key] && this[key] !== "N/A")
        .map(
          (attributeKey) => EuroCupEntry.attributeKeyToColumnName[attributeKey]
        ).filter((key) => key);
    } else {
      return [];
    }
  }
}

export default EuroCupEntry;
