import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const RedirectButton = () => {
  const [disabled, setDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(60);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    } else {
      setDisabled(false);
    }
  }, [timeLeft]);

  const handleClick = () => {
    if (!location.pathname.endsWith("random")) {
        navigate(`${location.pathname}/random`);
    }
    window.location.reload();
  };

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      variant="contained"
      sx={{
        position: "absolute",
        left: "85%",
        top: "50%",
        transform: "translateY(-50%)",
        fontFamily: "Arial",
        fontSize: "12px",
      }}>
      {disabled ? `Enabled in ${timeLeft} seconds` : "Start a random game"}
    </Button>
  );
};

export default RedirectButton;
