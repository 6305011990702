import React from "react";
import { Box, Typography } from "@mui/material";

export const PlayerPortrait = ({ playerData }) => {
  const playerPortraitPath = `${process.env.PUBLIC_URL}/img/player_portraits/${playerData.playerId}.png`;

  return (
    <Box display="flex" alignItems="center" width="150px">
      <img
        src={playerPortraitPath}
        alt="Player portrait"
        style={{ width: 40, height: 50, marginRight: 10 }} // Adjust dimensions as needed
      />
      <Typography variant="body1">{playerData.name}</Typography>
    </Box>
  );
};

export const PlayerNationality = ({ playerData: playerNationality }) => {
  const nationalFlagPath =
    playerNationality && playerNationality !== ""
      ? `${
          process.env.PUBLIC_URL
        }/img/national_flags/flag-of-${playerNationality.replace(
          /[, ']/g,
          "-"
        )}.png`
      : "unknown";

  return (
    <Box display="flex" alignItems="center">
      <img
        src={nationalFlagPath}
        alt="Flag"
        style={{ width: 51, height: 30, marginRight: 10 }} // Adjust dimensions as needed
      />
      <Typography variant="body1">{playerNationality}</Typography>
    </Box>
  );
};

export const ClubPortrait = ({ playerData: [playerClub, playerClubUrl]}) => {
  const clubIds = playerClubUrl.split("https://")[1].split("/");
  const clubId = clubIds[1] + "_" + clubIds[4].trim();
  const clubPortraitPath = `${process.env.PUBLIC_URL}/img/club_portraits/${clubId}.png`;

  return (
    <Box display="flex" alignItems="center" width="180px">
      <img
        src={clubPortraitPath}
        alt="Club portrait"
        style={{ width: 40, height: 40, marginRight: 10 }} // Adjust dimensions as needed
      />
      <Typography variant="body1">
        {playerClub}
      </Typography>
    </Box>
  );
};
