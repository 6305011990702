import React from "react";
import {
  Box,
  ButtonBase,
  Container,
  Typography,
  Grid,
  Link,
  Divider,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        bgcolor: "background.paper",
      }}>
      <Divider sx={{ width: "100%" }} />
      <Container maxWidth="lg" sx={{ padding: "10px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography variant="h6" color="text.primary" gutterBottom>
                About
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ️⚽ <strong>Futdle.io</strong> is a collection of football
                trivia games
                <br></br>
                🎮 Inspired by{" "}
                <Link
                  href="https://www.nytimes.com/games/wordle/index.html"
                  sx={{ fontWeight: "bold", color: "primary.dark" }}>
                  Wordle
                </Link>
                ,{" "}
                <Link
                  href="https://www.gamedle.wtf/guess?lang=en#"
                  sx={{ fontWeight: "bold", color: "primary.dark" }}>
                  Gamedle
                </Link>
                ,{" "}
                <Link
                  href="https://www.cinenerdle2.app/"
                  sx={{ fontWeight: "bold", color: "primary.dark" }}>
                  CineNerdle
                </Link>{" "}
                and{" "}
                <Link
                  href="https://www.twitch.tv/northernlion"
                  sx={{ fontWeight: "bold", color: "primary.dark" }}>
                  @Northernlion
                </Link>
                <br></br>
                🧑‍💻 Developed by a single developer
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Credits
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                <GitHubIcon sx={{ fontSize: "inherit", paddingLeft: "3px" }} />{" "}
                <Link
                  href="https://github.com/andyjiang007"
                  sx={{ fontWeight: "bold", color: "primary.dark" }}>
                  Github: Andy Jiang
                </Link>
                <br></br>
                🔄
                <Link
                  href="https://www.transfermarkt.com/"
                  sx={{ fontWeight: "bold", color: "primary.dark" }}>
                  Transfermarkt.com
                </Link>
              </Typography>
              <Typography variant="body2" color="text.primary" gutterBottom>
                Data Last Updated: 11/01/2024
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Feedback
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Link
                  href="mailto:contact@futdle.io"
                  sx={{ fontWeight: "bold", color: "primary.dark" }}>
                  contact@futdle.io
                </Link>
              </Typography>
              {/* IconButton with Buy me a coffee icon */}
              <ButtonBase
                component="a"
                href="https://ko-fi.com/futdleio"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src="/kofi_button_stroke.svg"
                  alt="Kofi Button"
                  style={{ width: 205, height: 32, paddingTop: 20}}
                />
              </ButtonBase>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
